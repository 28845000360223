










































































































import { defineComponent, reactive, Ref, ref } from '@vue/composition-api'
import InputBox from '@/components/InputBox.vue'
import LoadMore from '@/components/LoadMore.vue'
import { Backtop, Col, Empty, Popconfirm, Row, Tag } from 'element-ui'
import { getProjectsApi, restoreProjectApi } from '@/api/project'
import { ProjectStatus } from '@/api/project/model'
import { showError } from '@/utils/common'
import LayoutHeader from '@/components/LayoutHeader.vue'
import { projectTopicOption } from '@/api/project/topic-option'
import { projectIndustryOption } from '@/api/project/industry-option'
import lodash from 'lodash'

export default defineComponent({
    components: {
        [Tag.name]: Tag,
        [Row.name]: Row,
        [Col.name]: Col,
        [Popconfirm.name]: Popconfirm,
        [Empty.name]: Empty,
        [Backtop.name]: Backtop,
        InputBox,
        LoadMore,
        LayoutHeader
    },
    setup(props, { root }) {
        const listInfo = reactive({
            page: 0,
            size: 20,
            name: '',
            topic: '',
            industry: '',
            loading: false,
            finished: false,
            first: true,
            isSearch: false,
            onLoad: () => {
                listInfo.page++
                getProjects()
            },
            init: () => {
                projectList.value = []
                listInfo.page = 0
                listInfo.finished = false
                listInfo.isSearch = !!listInfo.name || !!listInfo.topic || !!listInfo.industry
                getProjects()
            }
        })


        const onProjectNameInput = lodash.debounce(listInfo.init, 1000)

        const projectTopicOptions = [
            {
                label: 'All',
                value: ''
            },
            ...projectTopicOption.map((v) => {
                return {
                    label: v,
                    value: v
                }
            })
        ]

        const projectIndustryOptions = [
            {
                label: 'All',
                value: ''
            },
            ...projectIndustryOption.map((v) => {
                return {
                    label: v,
                    value: v
                }
            })
        ]

        const projectList: Ref<any[]> = ref([])

        const getProjects = async () => {
            listInfo.first = false
            listInfo.loading = true
            try {
                const filters: any[] = [[['project_name', 'like', listInfo.name], ['or'], ['owner_name', 'like', listInfo.name]], ['and'], ['is_trashed', true]]
                if (listInfo.topic) {
                    filters.push(['and'], ['topic', '=', listInfo.topic])
                }
                if (listInfo.industry) {
                    filters.push(['and'], ['industry', '=', listInfo.industry])
                }
                const { items, last, total_pages } = await getProjectsApi({
                    page: listInfo.page,
                    size: listInfo.size,
                    filters: JSON.stringify(filters)
                })
                listInfo.finished = last || total_pages == 1
                projectList.value = [...projectList.value, ...items]
                listInfo.loading = false
            } catch (error) {
                showError(error)
            }
        }
        getProjects()

        const onProjectRestore = async (item: any, index: number) => {
            try {
                await root.$msgbox({
                    showCancelButton: true,
                    dangerouslyUseHTMLString: true,
                    message: <string>root.$i18n.t('project.confirm.recoverTitle')
                })

                await restoreProjectApi({
                    uuid: item.uuid
                })
                projectList.value.splice(index, 1)
                root.$message.success(<string>root.$i18n.t('recoverSuccess'))
            } catch (error) {
                if (error != 'cancel') {
                    showError(error)
                }
            }
        }

        return {
            listInfo,
            onProjectNameInput,
            projectList,
            ProjectStatus,
            projectTopicOptions,
            projectIndustryOptions,
            onProjectRestore
        }
    }
})
